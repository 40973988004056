<template>
  <base-layout :do-skip-header="false">
    <template #main>
      <h1 class='text-3xl md:text-4xl font-bold my-8'>
        The Program of the EBM 2024 Cyprus
      </h1>

        <div class="hidden sm:block">
          <div class="border-b border-gray-200">
            <nav class="-mb-px flex space-x-8" aria-label="Tabs">
              <!-- <button :class="tabClass('0')" @click="currentTab = '0'">{{ formatDay(days[0]) }}</button>
              <button :class="tabClass('1')" @click="currentTab = '1'">{{ formatDay(days[1]) }}</button>
              <button :class="tabClass('2')" @click="currentTab = '2'">{{ formatDay(days[2]) }}</button> -->
              <button :class="tabClass('0')" @click="currentTab = '0'">Wednesday October 9</button>
              <button :class="tabClass('1')" @click="currentTab = '1'">Thursday October 10</button>
              <button :class="tabClass('2')" @click="currentTab = '2'">Friday October 11</button>
            </nav>
          </div>

          <section v-if="currentTab === '0'">
            <!-- <h3 class="text-2xl font-semibold tracking-tight text-red-700"><time datetime="2022-10-04">Wednesday October 9</time></h3>
            <p class="mt-1.5 text-base tracking-tight text-indigo-900">The first day of the conference lets you settle in with some nice drinks</p> -->
            <ol role="list" class="mt-10 space-y-8 bg-white/60 px-10 py-14 text-left shadow-xl shadow-indigo-900/5 backdrop-blur">
              <li class="pb-16">
                <div class="w-24 float-left">
                  <img :src='program[0].events[0].icon'>
                </div>

                <div class="float-left ml-4">
                  <h4 class="text-lg font-semibold tracking-tight text-red-900">{{program[0].events[0].title}}</h4>
                  <p class="mt-1 font-mono text-sm text-slate-500">
                    <time :datetime="program[0].events[0].metaStart">{{program[0].events[0].timeStart}}</time>
                    -
                    <time :datetime="program[0].events[0].metaEnd">{{program[0].events[0].timeEnd}}</time>
                  </p>
                </div>
              </li>
              <li class="pb-16">
                <div class="mx-auto w-full mb-8 h-px w-48 bg-indigo-500/10"></div>
                  <div class="w-24 float-left">
                    <img :src='program[0].events[1].icon'>
                  </div>

                  <div class="float-left ml-4">
                    <h4 class="text-lg font-semibold tracking-tight text-red-900" v-html="program[0].events[1].title"></h4>
                    <!-- <p class="mt-1 tracking-tight text-indigo-900">Welcome drinks for registered attendees at the Parklane Cyprus</p> -->
                    <p class="mt-1 font-mono text-sm text-slate-500">
                      <time :datetime="program[0].events[1].metaStart">{{program[0].events[1].timeStart}}</time>
                      -
                      <time :datetime="program[0].events[1].metaEnd">{{program[0].events[1].timeEnd}}</time>
                    </p>
                  </div>
              </li>
            </ol>
          </section>

          <section v-if="currentTab === '1'">
            <!-- <h3 class="text-2xl font-semibold tracking-tight text-red-700"><time datetime="2022-10-05">Thursday October 10</time></h3>
            <p class="mt-1.5 text-base tracking-tight text-indigo-900">Get down to it with loads of One-on-One meetings and feast at our Dinner Party with live music</p> -->
            <ol role="list" class="mt-10 space-y-8 bg-white/60 px-10 py-14 text-left shadow-xl shadow-indigo-900/5 backdrop-blur">
              <li class="pb-16">
                <div class="w-24 float-left">
                  <img :src='program[1].events[0].icon'>
                </div>

                <div class="float-left ml-4">
                  <h4 class="text-lg font-semibold tracking-tight text-red-900">{{program[1].events[0].title}}</h4>
                  <p class="mt-1 font-mono text-sm text-slate-500">
                    <time :datetime="program[1].events[0].metaStart">{{program[1].events[0].timeStart}}</time>
                    -
                    <time :datetime="program[1].events[0].metaEnd">{{program[1].events[0].timeEnd}}</time>
                  </p>
                </div>
              </li>
              <li class="pb-16">
                <div class="mx-auto mb-8 w-full h-px w-48 bg-indigo-500/10"></div>
                <div class="w-24 float-left">
                  <img :src='program[1].events[1].icon'>
                </div>

                <div class="float-left ml-4">
                  <h4 class="text-lg font-semibold tracking-tight text-red-900">{{program[1].events[1].title}}</h4>
                  <p class="mt-1 font-mono text-sm text-slate-500">
                    <time :datetime="program[1].events[1].metaStart">{{program[1].events[1].timeStart}}</time>
                    -
                    <time :datetime="program[1].events[1].metaStart">{{program[1].events[1].timeEnd}}</time>
                  </p>
                </div>
              </li>
              <li class="pb-16">
                <div class="mx-auto mb-8 w-full h-px w-48 bg-indigo-500/10"></div>
                <div class="w-24 float-left">
                  <img :src='program[1].events[2].icon'>
                </div>

                <div class="float-left ml-4">
                  <h4 class="text-lg font-semibold tracking-tight text-red-900">{{program[1].events[2].title}}</h4>
                  <p class="mt-1 font-mono text-sm text-slate-500">
                    <time :datetime="program[1].events[2].metaStart">{{program[1].events[2].timeStart}}</time>
                    -
                    <time :datetime="program[1].events[2].metaStart">{{program[1].events[2].timeEnd}}</time>
                  </p>
                </div>
              </li>
              <li class="pb-16">
                <div class="mx-auto mb-8 w-full h-px w-48 bg-indigo-500/10"></div>
                <div class="w-24 float-left">
                  <img :src='program[1].events[3].icon'>
                </div>

                <div class="float-left ml-4">
                  <h4 class="text-lg font-semibold tracking-tight text-red-900">{{program[1].events[3].title}}</h4>
                  <p class="mt-1 font-mono text-sm text-slate-500">
                    <time :datetime="program[1].events[3].metaStart">{{program[1].events[3].timeStart}}</time>
                    -
                    <time :datetime="program[1].events[3].metaStart">{{program[1].events[3].timeEnd}}</time>
                  </p>
                </div>
              </li>
              <li class="pb-16">
                <div class="mx-auto mb-8 w-full h-px w-48 bg-indigo-500/10"></div>
                <div class="w-24 float-left">
                  <img :src='program[1].events[4].icon'>
                </div>

                <div class="float-left ml-4">
                  <h4 class="text-lg font-semibold tracking-tight text-red-900">{{program[1].events[4].title}}</h4>
                  <p class="mt-1 font-mono text-sm text-slate-500">
                    <time :datetime="program[1].events[3].metaStart">{{program[1].events[4].timeStart}}</time>
                    -
                    <time :datetime="program[1].events[3].metaStart">{{program[1].events[4].timeEnd}}</time>
                  </p>
                </div>
              </li>
            </ol>
          </section>
          <section v-if="currentTab === '2'">
            <!-- <h3 class="text-2xl font-semibold tracking-tight text-red-700"><time datetime="2022-10-06">Friday October 11</time></h3>
            <p class="mt-1.5 text-base tracking-tight text-indigo-900">Another day slam packed with meets. Afterwards we'll dance to the beats at a surprise location.</p> -->
            <ol role="list" class="mt-10 space-y-8 bg-white/60 px-10 py-14 text-left shadow-xl shadow-indigo-900/5 backdrop-blur">
              <li class="pb-16">
                <div class="w-24 float-left">
                  <img :src='program[2].events[0].icon'>
                </div>

                <div class="float-left ml-4">
                  <h4 class="text-lg font-semibold tracking-tight text-red-900">{{program[2].events[0].title}}</h4>
                  <p class="mt-1 font-mono text-sm text-slate-500">
                    <time :datetime="program[2].events[0].metaStart">{{program[2].events[0].timeStart}}</time>
                    -
                    <time :datetime="program[2].events[0].metaStart">{{program[2].events[0].timeEnd}}</time>
                  </p>
                </div>
              </li>
              <li class="pb-16">
                <div class="mx-auto mb-8 w-full h-px w-48 bg-indigo-500/10"></div>
                <div class="w-24 float-left">
                  <img :src='program[2].events[1].icon'>
                </div>

                <div class="float-left ml-4">
                  <h4 class="text-lg font-semibold tracking-tight text-red-900">{{program[2].events[1].title}}</h4>
                  <p class="mt-1 font-mono text-sm text-slate-500">
                    <time :datetime="program[2].events[1].metaStart">{{program[2].events[1].timeStart}}</time>
                    -
                    <time :datetime="program[2].events[1].metaStart">{{program[2].events[1].timeEnd}}</time>
                  </p>
                </div>
              </li>
              <li class="pb-16">
                <div class="mx-auto mb-8 w-full h-px w-48 bg-indigo-500/10"></div>
                <div class="w-24 float-left">
                  <img :src='program[2].events[2].icon'>
                </div>

                <div class="float-left ml-4">
                  <h4 class="text-lg font-semibold tracking-tight text-red-900">{{program[2].events[2].title}}</h4>
                  <p class="mt-1 font-mono text-sm text-slate-500">
                    <time :datetime="program[2].events[2].metaStart">{{program[2].events[2].timeStart}}</time>
                    -
                    <time :datetime="program[2].events[2].metaStart">{{program[2].events[2].timeEnd}}</time>
                  </p>
                </div>
              </li>
              <li class="pb-16">
                <div class="mx-auto mb-8 w-full h-px w-48 bg-indigo-500/10"></div>
                <div class="w-24 float-left">
                  <img :src='program[2].events[3].icon'>
                </div>

                <div class="float-left ml-4">
                  <h4 class="text-lg font-semibold tracking-tight text-red-900">{{program[2].events[3].title}}</h4>
                  <p class="mt-1 font-mono text-sm text-slate-500">
                    <time :datetime="program[2].events[2].metaStart">{{program[2].events[3].timeStart}}</time>
                    -
                    <time :datetime="program[2].events[2].metaStart">{{program[2].events[3].timeEnd}}</time>
                  </p>
                </div>
              </li>
            </ol>
          </section>
        </div>

        <!-- small screens //-->

        <div class="block sm:hidden">
          <section>
            <!-- <h3 class="text-2xl font-semibold tracking-tight text-red-700"><time datetime="2022-10-04">Wednesday October 9</time></h3>
            <p class="mt-1.5 text-base tracking-tight text-indigo-900">The first day of the conference lets you settle in with some nice drinks</p> -->
            <div class="mt-10 text-xl font-bold">Wednesday October 9</div>

            <ol role="list" class="mt-6 space-y-4 bg-white/60 px-10 py-8 text-left shadow-xl shadow-indigo-900/5 backdrop-blur">
              <li>
                <h4 class="text-lg font-semibold tracking-tight text-red-900">{{program[0].events[0].title}}</h4>
                <p class="mt-1 font-mono text-sm text-slate-500">
                  <time :datetime="program[0].events[0].metaStart">{{program[0].events[0].timeStart}}</time>
                  -
                  <time :datetime="program[0].events[0].metaEnd">{{program[0].events[0].timeEnd}}</time>
                </p>
              </li>
              <li>
                <div class="mx-auto w-full mb-4 h-px w-48 bg-indigo-500/10"></div>
                <h4 class="text-lg font-semibold tracking-tight text-red-900" v-html="program[0].events[1].title"></h4>
                <!-- <p class="mt-1 tracking-tight text-indigo-900">Welcome drinks for registered attendees at the Parklane Cyprus</p> -->
                <p class="mt-1 font-mono text-sm text-slate-500">
                  <time :datetime="program[0].events[1].metaStart">{{program[0].events[1].timeStart}}</time>
                  -
                  <time :datetime="program[0].events[1].metaEnd">{{program[0].events[1].timeEnd}}</time>
                </p>
              </li>
            </ol>
          </section>

          <section>
            <!-- <h3 class="text-2xl font-semibold tracking-tight text-red-700"><time datetime="2022-10-05">Thursday October 10</time></h3>
            <p class="mt-1.5 text-base tracking-tight text-indigo-900">Get down to it with loads of One-on-One meetings and feast at our Dinner Party with live music</p> -->
            <div class="mt-10 text-xl font-bold">Thursday October 10</div>

            <ol role="list" class="mt-6 space-y-4 bg-white/60 px-10 py-8 text-left shadow-xl shadow-indigo-900/5 backdrop-blur">
              <li>
                <h4 class="text-lg font-semibold tracking-tight text-red-900">{{program[1].events[0].title}}</h4>
                <p class="mt-1 font-mono text-sm text-slate-500">
                  <time :datetime="program[1].events[0].metaStart">{{program[1].events[0].timeStart}}</time>
                  -
                  <time :datetime="program[1].events[0].metaEnd">{{program[1].events[0].timeEnd}}</time>
                </p>
              </li>
              <li>
                <div class="mx-auto mb-4 w-full h-px w-48 bg-indigo-500/10"></div>
                <h4 class="text-lg font-semibold tracking-tight text-red-900">{{program[1].events[1].title}}</h4>
                <p class="mt-1 font-mono text-sm text-slate-500">
                  <time :datetime="program[1].events[1].metaStart">{{program[1].events[1].timeStart}}</time>
                  -
                  <time :datetime="program[1].events[1].metaStart">{{program[1].events[1].timeEnd}}</time>
                </p>
              </li>
              <li>
                <div class="mx-auto mb-4 w-full h-px w-48 bg-indigo-500/10"></div>
                <h4 class="text-lg font-semibold tracking-tight text-red-900">{{program[1].events[2].title}}</h4>
                <p class="mt-1 font-mono text-sm text-slate-500">
                  <time :datetime="program[1].events[2].metaStart">{{program[1].events[2].timeStart}}</time>
                  -
                  <time :datetime="program[1].events[2].metaStart">{{program[1].events[2].timeEnd}}</time>
                </p>
              </li>
              <li>
                <div class="mx-auto mb-4 w-full h-px w-48 bg-indigo-500/10"></div>
                <h4 class="text-lg font-semibold tracking-tight text-red-900">{{program[1].events[3].title}}</h4>
                <p class="mt-1 font-mono text-sm text-slate-500">
                  <time :datetime="program[1].events[3].metaStart">{{program[1].events[3].timeStart}}</time>
                  -
                  <time :datetime="program[1].events[3].metaStart">{{program[1].events[3].timeEnd}}</time>
                </p>
              </li>
              <li>
                <div class="mx-auto mb-4 w-full h-px w-48 bg-indigo-500/10"></div>
                <h4 class="text-lg font-semibold tracking-tight text-red-900">{{program[1].events[4].title}}</h4>
                <p class="mt-1 font-mono text-sm text-slate-500">
                  <time :datetime="program[1].events[3].metaStart">{{program[1].events[4].timeStart}}</time>
                  -
                  <time :datetime="program[1].events[3].metaStart">{{program[1].events[4].timeEnd}}</time>
                </p>
              </li>
            </ol>
          </section>
          <section>
            <!-- <h3 class="text-2xl font-semibold tracking-tight text-red-700"><time datetime="2022-10-06">Friday October 11</time></h3>
            <p class="mt-1.5 text-base tracking-tight text-indigo-900">Another day slam packed with meets. Afterwards we'll dance to the beats at a surprise location.</p> -->
            <div class="mt-10 text-xl font-bold">Friday October 11</div>

            <ol role="list" class="mt-6 space-y-4 bg-white/60 px-10 py-8 text-left shadow-xl shadow-indigo-900/5 backdrop-blur">
              <li>
                <h4 class="text-lg font-semibold tracking-tight text-red-900">{{program[2].events[0].title}}</h4>
                <p class="mt-1 font-mono text-sm text-slate-500">
                  <time :datetime="program[2].events[0].metaStart">{{program[2].events[0].timeStart}}</time>
                  -
                  <time :datetime="program[2].events[0].metaStart">{{program[2].events[0].timeEnd}}</time>
                </p>
              </li>
              <li>
                <div class="mx-auto mb-4 w-full h-px w-48 bg-indigo-500/10"></div>
                <h4 class="text-lg font-semibold tracking-tight text-red-900">{{program[2].events[1].title}}</h4>
                <p class="mt-1 font-mono text-sm text-slate-500">
                  <time :datetime="program[2].events[1].metaStart">{{program[2].events[1].timeStart}}</time>
                  -
                  <time :datetime="program[2].events[1].metaStart">{{program[2].events[1].timeEnd}}</time>
                </p>
              </li>
              <li>
                <div class="mx-auto mb-4 w-full h-px w-48 bg-indigo-500/10"></div>
                <h4 class="text-lg font-semibold tracking-tight text-red-900">{{program[2].events[2].title}}</h4>
                <p class="mt-1 font-mono text-sm text-slate-500">
                  <time :datetime="program[2].events[2].metaStart">{{program[2].events[2].timeStart}}</time>
                  -
                  <time :datetime="program[2].events[2].metaStart">{{program[2].events[2].timeEnd}}</time>
                </p>
              </li>
              <li>
                <div class="mx-auto mb-4 w-full h-px w-48 bg-indigo-500/10"></div>
                <h4 class="text-lg font-semibold tracking-tight text-red-900">{{program[2].events[3].title}}</h4>
                <p class="mt-1 font-mono text-sm text-slate-500">
                  <time :datetime="program[2].events[2].metaStart">{{program[2].events[3].timeStart}}</time>
                  -
                  <time :datetime="program[2].events[2].metaStart">{{program[2].events[3].timeEnd}}</time>
                </p>
              </li>
            </ol>
          </section>        
        </div>

      <div id="regInfo" class="text-lg leading-7 text-gray-700 mt-12 mb-8 space-y-8 bg-white/60 px-10 py-14 shadow-xl shadow-indigo-900/5 backdrop-blur">
        <h3 class="text-2xl font-semibold tracking-tight text-indigo-900 mb-4">Registration Info</h3>
        <p>
          Registration fee for the 20th European Broker Meeting is <strong>€ 995,- </strong> per attendee for TBS members, 
          non TBS members pay <strong>€ 1670,- </strong>for the first attendee (1 year TBS Plus Membership included) and <strong>€ 995,-</strong> for every other attendee. 
          From the 1st of July, <strong>€ 100,-</strong> per attendee is donated to <a href="https://www.it4kids.com/en/home-en/" target="_blank" rel="noopener" class="text-red-700">IT4Kids</a>.
        </p>
        <p>
          As expected our roomblock at the Parklane Cyprus has been sold out, there are still rooms available at the Parklane but not at our event rate: <a href="https://www.marriott.com/en-us/hotels/pfomd-parklane-a-luxury-collection-resort-and-spa-limassol/overview/" target="_blank" rel="noopener" class="text-red-700 break-words">https://www.marriott.com/en-us/hotels/pfomd-parklane-a-luxury-collection-resort-and-spa-limassol/overview/</a><br /><br />
          Alternative hotels within walking distance:<br /><br />
          <a href="https://www.google.com/maps/place/St+Raphael+Resort/@34.7121163,33.1641784,16.55z/data=!4m20!1m10!3m9!1s0x14e0ca2a8d63ff4d:0xdc93062b55b67add!2sSt+Raphael+Resort!5m2!4m1!1i2!8m2!3d34.7127851!4d33.1673799!16s%2Fg%2F1td6vggl!3m8!1s0x14e0ca2a8d63ff4d:0xdc93062b55b67add!5m2!4m1!1i2!8m2!3d34.7127851!4d33.1673799!16s%2Fg%2F1td6vggl?entry=ttu" target="_blank" rel="noopener" class="text-red-700 break-words">Hotel St. Raphael</a>, prices starting at <strong>&euro;275 per room per night</strong>, reservation link: <a href="https://book.straphael.com/?checkin=2024-10-09&rooms=1&nights=3&adults=2&bkcode=oneevent" target="_blank" rel="noopener" class="text-red-700 break-words">https://book.straphael.com/?checkin=2024-10-09&rooms=1&nights=3&adults=2&bkcode=oneevent</a><br /><br />
          <a href="https://www.google.com/maps/place/The+GrandResort+by+Leonardo+Hotels+-+Limited+Edition/@34.7137794,33.1633687,17z/data=!4m10!3m9!1s0x14e0cbd34e74730d:0x1f35efe5cd8dac31!5m3!1s2024-05-03!4m1!1i2!8m2!3d34.7136119!4d33.1626284!16s%2Fg%2F1tmcvbht?entry=ttu" target="_blank" rel="noopener" class="text-red-700 break-words">The GrandResort Hotel</a>, prices starting at <strong>&euro;266 per room per night</strong> (with promo code <strong>EBM2024</strong>), reservation link: <a href="https://grandresort.reserve-online.net/?checkin=2024-10-09&rooms=1&nights=1&adults=1" target="_blank" rel="noopener" class="text-red-700 break-words">https://grandresort.reserve-online.net/?checkin=2024-10-09&rooms=1&nights=1&adults=1</a><br />
        </p>
      </div>
    </template>
  </base-layout>
</template>

<script>
import _ from "lodash";
import BaseLayout from "../components/BaseLayout.vue";

export default {
  name: "Register",
  components: {
    BaseLayout,
  },
  data() {
    return {
      days: ['2024-10-09', '2024-10-10', '2024-10-11'],
      currentTab: 0,
      program: [
        {
          'events': [
            {
              'timeStart':'15:00',
              'timeEnd':'20:00',
              'metaStart':'2024-10-09T15:00',
              'metaEnd':'2024-10-09T20:00',
              'title':'Early registration',
              'icon':'/images/icon_register.png',
            },
            {
              'timeStart':'18:00',
              'timeEnd':'20:00',
              'metaStart':'2024-10-09T18:00',
              'metaEnd':'2024-10-09T20:00',
              'title':'Welcome drinks for registered attendees at the Parklane Cyprus featuring <a href="https://music.youtube.com/channel/UCCQgaP93DsJEs50uhYuhA6g" target="_blank" rel="noopener">Dj Costta Ta from C- COM Paris',
              'icon':'/images/icon_drinks.png',
            },
          ]
        },
        {
          'events': [
            {
              'timeStart':'09:00',
              'timeEnd':'18:00',
              'metaStart':'2024-10-10T09:00',
              'metaEnd':'2024-10-10T18:00',
              'title':'Registration',
              'icon':'/images/icon_register.png',
            },
            {
              'timeStart':'10:00',
              'timeEnd':'18:00',
              'metaStart':'2024-10-10T10:00',
              'metaEnd':'2024-10-10T18:00',
              'title':'One-on-One meetings',
              'icon':'/images/icon_meetings.png',
            },
            {
              'timeStart':'12:00',
              'timeEnd':'14:00',
              'metaStart':'2024-10-10T12:00',
              'metaEnd':'2024-10-10T14:00',
              'title':'Lunch at the Parklane Cyprus',
              'icon':'/images/icon_lunch.png',
            },
            {
              'timeStart':'12:30',
              'timeEnd':'14:30',
              'metaStart':'2024-10-10T12:30',
              'metaEnd':'2024-10-10T14:30',
              'title':`Captain's Lunch at the Rose meeting room`,
              'icon':'/images/icon_captains.png',
            },
            {
              'timeStart':'19:00',
              'timeEnd':'22:00',
              'metaStart':'2024-10-10T19:00',
              'metaEnd':'2024-10-10T22:00',
              'title':'Celebratory dinner with live music at the Parklane Cyprus',
              'icon':'/images/icon_anniv.png',
            },
          ]
        },
        {
          'events': [
            {
              'timeStart':'10:00',
              'timeEnd':'18:00',
              'metaStart':'2024-10-11T10:00',
              'metaEnd':'2024-10-11T18:00',
              'title':'One-on-One meetings',
              'icon':'/images/icon_meetings.png',
            },
            {
              'timeStart':'12:00',
              'timeEnd':'14:00',
              'metaStart':'2024-10-11T12:00',
              'metaEnd':'2024-10-11T14:00',
              'title':'Lunch at the Parklane Cyprus',
              'icon':'/images/icon_lunch.png',
            },
            {
              'timeStart':'12:30',
              'timeEnd':'14:30',
              'metaStart':'2024-10-10T12:30',
              'metaEnd':'2024-10-10T14:30',
              'title':`Captain's Lunch at the Rose meeting room`,
              'icon':'/images/icon_captains.png',
            },
            {
              'timeStart':'19:00',
              'timeEnd':'23:00',
              'metaStart':'2024-10-11T19:00',
              'metaEnd':'2024-10-11T23:00',
              'title':'Farewell dinner party at Aplostra Beach Bar, with the announcement of the #EBM2025 free ticket winners.',
              'icon':'/images/icon_dinner.png',
            },
          ]
        },
      ]
    };
  },
  methods: {
    formatDay(dateStr, type) { // added by Brian to format the date in the scheduler tabs
      const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      const dateObj = new Date(dateStr);
      const day = daysOfWeek[dateObj.getDay()];
      const month = months[dateObj.getMonth()];
      const date = dateObj.getDate();

      return `${day} ${month} ${date}`;
    },

    tabClass(index) {
      this.currentTab = String(this.currentTab) // Added by Brian: convert to string, because when using mobile, the value is passed as an integer and the === won't work, results in only displaying the cancelled meetings tab
      if (+index === +this.currentTab) return 'border-red-500 text-red-600 flex whitespace-nowrap border-b-2 py-4 px-1 font-medium'
      return 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700 flex whitespace-nowrap border-b-2 py-4 px-1font-medium';
    },

  },
};
</script>
